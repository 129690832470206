'use client';

import { memo } from 'react';

import { supressNoisyLogs } from '@/lib/logging';

export const LoggerWrapper = memo(() => {
  supressNoisyLogs();
  return null;
});
