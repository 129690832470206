import config from '@/lib/config';

// Logs to supress.
const regexes = [
  /React does not recognize the `(.+?)` prop on a DOM element\..*/,
  /Received `(.+?)` for a non-boolean attribute `(.+?)`\./,
  /Prop `href` did not match. Server: "/,
];

/*
 * Supress Logs that are too noisy and are getting in the way of development.
 *
 * This still logs them as .debug()
 */
export function supressNoisyLogs() {
  if (config.NODE_ENV !== 'development') {
    return;
  }

  const originalErrorLogger = console.error;

  console.error = function (...args) {
    const message = args[0];

    if (typeof message === 'string') {
      for (const regex of regexes) {
        const match = regex.test(message);

        if (match) {
          const unrecognizedProp = match[1]; // Capture the prop name
          console.debug(`Silenced warning about prop: ${unrecognizedProp}`);
          return; // Skip this warning
        }
      }
    }

    originalErrorLogger.apply(console, args); // Log other warnings
  };
}
